<template>
  <div class="wrapper hei">
    <!-- 扫码提示语 -->
    <tips subtit="开始门票充值"></tips>
    <!-- 键盘与版本号 -->
    <div class="res pv-4x bg-gray-dark keybord flex-grow">
      <keyboard
        :open-idcard="true"
        type="1"
        v-model="numbers"
        @ok="ok"
        place="请扫码或输入票号"
        @clear="clear"
      >
        <!-- <a-input
          slot="input"
          allow-clear
          v-model="numbers"
          placeholder="请扫码或输入票号"
        >
        </a-input> -->
      </keyboard>
    </div>
    <!-- 版本号 -->
    <bottom-ota></bottom-ota>
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :type="resultType"
      :text="resultText"
    ></Result>
  </div>
</template>
<script>
import Tips from "@/components/common/tips";
import Keyboard from "@/components/common/keyboard";
import BottomOta from "@/components/common/bottom-ota";
import { recharge } from "../../api/cart";
import Result from "../../components/dialog/result.vue";

export default {
  name: "Recharge",
  components: {
    Tips,
    Keyboard,
    BottomOta,
    Result
  },
  props: {},
  data() {
    return {
      numbers: "",
      resultDialog: false,
      resultType: 1,
      resultText: ""
    };
  },
  created() {
    this.$speak("请将IC卡放置在下方读卡器或将门票二维码对准右下角扫描器");
  },
  methods: {
    // 键盘确定
    ok() {
      if (!this.numbers) {
        this.$speak("请先输入号码");
        this.resultText = "请先输入号码";
        this.resultType = 1;
        this.resultDialog = true;
        return;
      }
      recharge(
        {
          ticketNo: this.numbers, // 票号，卡/腕带号（通过url传参）
          memberMobile: "" // 会员手机号
        },
        {
          customResult: true // 报错时，自定义弹窗
        }
      )
        .then(res => {
          if (res && res.ticketName) {
            this.$store.dispatch("recharge/setTicketRecharge", res);
            this.$router.push({
              name: "recharge-list",
              query: { cartid: res.id }
            });
          }
        })
        .catch(res => {
          const msg = (res.data && res.data.message) || "查询不到信息";
          this.numbers = "";
          this.resultText = msg;
          this.resultType = 2;
          this.resultDialog = true;
        });
    },
    clear() {
      this.numbers = "";
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.keybord {
  padding-left: @1x * 10;
  padding-right: @1x * 10;
  box-sizing: border-box;
}
</style>
