<template>
  <div class="wrapper hei">
    <!-- 门票 -->
    <div class="container">
      <div class="card flex-start">
        <img class="ticket-img mr-3x" :src="ticketDetail.ticketImage" />
        <div class="card-text-wrap">
          <p class="font-xl text-1 line-height">
            {{ ticketDetail.ticketName }}
          </p>
          <p class="font-l gray line-height">
            门票类型：{{ ticketDetail.productCategoryName }}
          </p>
          <p class="font-l gray line-height">
            <span class="mr-1x">票号：</span>
            <span>{{ ticketDetail.ticketNo }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- <span class="text-c">可充值套餐</span> -->

    <div class="flex-around switch-tab">
      <div
        @click="tabClick(1)"
        class="tab-btn"
        :class="{ active: tabIndex == 1 }"
      >
        门票资产套餐
      </div>
      <div
        @click="tabClick(2)"
        class="tab-btn"
        :class="{ active: tabIndex == 2 }"
      >
        项目套餐
      </div>
    </div>

    <!-- 门票资产套餐 -->
    <div v-show="tabIndex == 1" class="slide ul-wrap container flex-grow mt-3x">
      <ul v-if="ticketList && ticketList.length">
        <li
          class="card flex-start relative"
          v-for="(item, index) in ticketList"
          :key="index"
        >
          <img class="ticket-img mr-3x" :src="item.skuImages" />
          <div class="card-text-wrap">
            <p class="font-xl text-1 line-height">
              {{ item.name }}
            </p>
            <div class="font-l gray flex-start line-height">
              <span>含：</span>
              <!-- 门票资产套餐的字段 -->
              <span
                v-if="item.assetsInfoList"
                class="text-1"
                style="max-width: 340px;"
              >
                {{ item.assetsInfoList ? item.assetsInfoList.join(",") : "" }}
              </span>
              <!-- 项目套餐的字段 -->
              <!-- <span
                v-if="item.relationProjectType == 2 && item.projectInfoList"
                class="text-1"
                style="max-width: 340px;"
              >
                {{ item.projectInfoList ? item.projectInfoList.join(",") : "" }}
              </span> -->
              <span v-if="item.relationProjectType == 1">全部项目</span>
            </div>
            <p
              v-if="item.buyNotice && item.buyNotice != 'null'"
              class="gray font-l notice"
              @click="openDialog(item)"
            >
              <a-icon
                class="gray mr-x"
                type="exclamation-circle"
                theme="filled"
              />
              购买须知
            </p>
            <div class="line-height">
              <span class="red mr-2x">{{ item.sellingPrice | money }}</span>
              <number-box
                @plusClick="next => plusClick(item, next)"
                @minusClick="next => minusClick(item, next)"
                class="num-box"
              ></number-box>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="container slide">
        <a-empty class="mt-4x font-l"></a-empty>
      </div>
    </div>
    <!-- 项目套餐 -->
    <div v-show="tabIndex == 2" class="slide ul-wrap container flex-grow mt-3x">
      <ul v-if="projectList && projectList.length">
        <li
          class="card flex-start relative"
          v-for="(item, index) in projectList"
          :key="index"
        >
          <img class="ticket-img mr-3x" :src="item.skuImages" />
          <div class="card-text-wrap">
            <p class="font-xl text-1 line-height">
              {{ item.name }}
            </p>
            <div class="font-l gray flex-start line-height">
              <span>含：</span>
              <!-- 门票资产套餐的字段 -->
              <!-- <span
                v-if="item.assetsInfoList"
                class="text-1"
                style="max-width: 340px;"
              >
                {{ item.assetsInfoList ? item.assetsInfoList.join(",") : "" }}
              </span> -->
              <!-- 项目套餐的字段 -->
              <span
                v-if="item.relationProjectType == 2 && item.projectInfoList"
                class="text-1"
                style="max-width: 340px;"
              >
                {{ item.projectInfoList ? item.projectInfoList.join(",") : "" }}
              </span>
              <span v-if="item.relationProjectType == 1">全部项目</span>
            </div>
            <p
              v-if="item.buyNotice && item.buyNotice != 'null'"
              class="gray font-l notice"
              @click="openDialog(item)"
            >
              <a-icon
                class="gray mr-x"
                type="exclamation-circle"
                theme="filled"
              />
              购买须知
            </p>
            <div class="line-height">
              <span class="red mr-2x">{{ item.sellingPrice | money }}</span>
              <number-box
                @plusClick="next => plusClick(item, next)"
                @minusClick="next => minusClick(item, next)"
                class="num-box"
              ></number-box>
            </div>
          </div>
        </li>
      </ul>
      <div v-else class="container slide">
        <a-empty class="mt-4x font-l"></a-empty>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        已选 {{ count }} 项 合计：<span class="red"
          >￥{{ totalMoney | money }}</span
        >
      </p>
      <a-button type="primary" class="btn" @click="sureBuy">确定选择</a-button>
    </div>

    <!-- 购买须知  弹窗 -->
    <basics v-model="showDialog" :hideDefaultHeader="true">
      <div slot="header" class="header flex-start pb-2x bd-b font-l text-1">
        {{ dialogData.name }}
      </div>
      <div
        slot="content"
        class="mt-4x font-xl"
        style="max-height:1000px;overflow-y: auto;"
      >
        <div
          v-html="dialogData.buyNotice"
          v-if="dialogData.buyNotice && dialogData.buyNotice != 'null'"
        ></div>
        <div v-else>暂无数据</div>
      </div>
    </basics>
  </div>
</template>
<script>
import NumberBox from "@/components/common/number-box";
import Basics from "@/components/dialog/basics";
import {
  productSelfTicketrecharge,
  productSelfProjectPackage
} from "../../api/search";
import {
  // recharge,
  manyProduct
} from "../../api/cart";
import { mapActions } from "vuex";

export default {
  name: "RechargeList",
  components: {
    NumberBox,
    Basics
  },
  props: {},
  data() {
    return {
      showDialog: false, // 购买须知弹窗
      dialogData: {},
      ticketDetail: {}, // 产品详细信息
      rechargeList: [], // 门票资产套餐与项目套餐合集
      projectList: [], // 项目套餐
      ticketList: [], // 门票资产套餐
      count: 0, // 已选多少项
      totalMoney: 0, // 价格总额
      tabIndex: 1
    };
  },
  created() {
    this.ticketDetail = this.$store.state.recharge.ticketRecharge;
    this.getProjectList(); // 项目套餐
    this.getData(); // 门票资产套餐
    this.$speak("请选择需要充值的套餐");
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    // 可充值的项目套餐列表
    getProjectList() {
      productSelfProjectPackage()
        .then(res => {
          this.projectList = res || []; // 预防：没有项目套餐
          this.projectList.forEach(item => {
            item.count = 0; // 自定义计数
          });
        })
        .catch(() => {});
    },
    // 可充值的门票资产套餐
    getData() {
      productSelfTicketrecharge()
        .then(res => {
          this.ticketList = res || [];
          this.ticketList.forEach(item => {
            item.count = 0; // 自定义计数
          });
          this.rechargeList = this.ticketList || [];
        })
        .catch(() => {});
    },
    // 购买须知弹窗
    openDialog(item) {
      this.dialogData = {
        buyNotice: item.buyNotice,
        name: item.name
      };
      this.showDialog = true;
    },
    // 加1
    plusClick(item, next) {
      item.count += 1;
      next();
      this.totalMoney = this.totalMoney + item.sellingPrice;
      this.count += 1;
    },
    // 减1
    minusClick(item, next) {
      item.count -= 1;
      next();
      this.totalMoney = this.totalMoney - item.sellingPrice;
      this.count -= 1;
    },
    tabClick(index) {
      this.tabIndex = index;
    },
    // 确定
    sureBuy() {
      if (this.count <= 0) {
        this.$warning("您还未选择要充值的套餐");
        return;
      }
      const id = this.$route.query.cartid || "";
      this.goToOrder(id);
      // // 生成购物车id
      // recharge({
      //   ticketNo: this.ticketDetail.ticketNo // 票号，卡/腕带号（通过url传参）
      // })
      //   .then(res => {
      //     this.goToOrder(res.id);
      //   })
      //   .catch(() => {});
    },
    // 跳转 - 确认订单
    goToOrder(carId) {
      const params = {
        id: carId, // 生成的购物id
        buyChannels: 8, // 购买渠道
        businessCategory: 4, // 订单业务类型
        productList: []
      };
      this.rechargeList = this.ticketList.concat(this.projectList);
      this.rechargeList.forEach(item => {
        params.productList.push({
          productId: item.productId,
          spuId: item.spuId,
          buyNum: item.count
        });
      });
      manyProduct(params)
        .then(() => {
          // this.$store.dispatch("cart/SetCartId", carId);
          this.$store.dispatch("order/setCartList", { id: carId });
          this.$store.dispatch("common/setPrintingRequired", false); // 不需要显示打印组件
          // 设置支付成功后，返回门票充值页面
          this.setEndOfPayConfig({
            logoutMember: true,
            backUrl: "/recharge"
          });
          this.$router.push({
            path: "/recharge-order",
            query: { backUrl: "/recharge-list" }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.ticket-img {
  width: 100px;
  height: 100px;
  border-radius: @2x;
}
.card-text-wrap {
  max-width: 550px;
  .line-height {
    line-height: 34px;
  }
  .notice {
    width: 180px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}

.ul-wrap {
  padding-top: 0;
}

.switch-tab {
  .tab-btn {
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: @1x * 8;
    background-color: #e1e1e1;
    &.active {
      background-color: @primary;
      color: #fff;
    }
  }
}

// 弹窗图片
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

/deep/.ant-table {
  font-size: 24px;
}
</style>
